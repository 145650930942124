/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useUi } from 'components/provider/Ui';
import HelloLogoText from 'components/shared/HelloLogoText';
import HelloStar from 'components/shared/HelloStar';

const DesktopNavHeader = () => {
  const { isSidebarExpanded, toggleSidebar } = useUi();
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '25px 30px 25px',
        cursor: 'pointer',
      }}
      onClick={() => toggleSidebar()}
    >
      <HelloStar
        style={{
          position: 'relative',
          left: '3px',
          height: '15px',
          marginRight: '10px'
        }}
      />
      <HelloLogoText
        style={{
          position: 'relative',
          left: 4,
          transition: 'all ease-in-out 0.3s',
          transform: isSidebarExpanded ? 'translateX(0px)' : 'translateX(50px)',
          opacity: isSidebarExpanded ? '1' : '0',
        }}
      />
    </div>
  )
}

export default DesktopNavHeader;
