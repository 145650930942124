/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { ReactComponent as MenuOpenIcon } from 'icons/menu_open.svg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useAuth } from 'components/provider/Auth';
import { useBreadcrumbs } from 'components/provider/Breadcrumbs';
import { useUi } from 'components/provider/Ui';
import Breadcrumb from 'components/shared/Breadcrumb';

import Util from 'utilities';

const Header = ({ isPhone }) => {
  const { account } = useAuth();
  const { breadcrumbs } = useBreadcrumbs();
  const { toggleSidebar } = useUi();

  return (
    <header
      style={{
        display: 'grid',
        gridTemplateColumns: '80px 1fr 80px',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"left center right"',
        position: 'absolute',
        top: 0,
        left: 0,
        transition: 'transform ease-in-out 0.5s',
        transitionDelay: account ? '0.7s' : '0s',
        transform: account ? 'translateY(0px)' : 'translateY(-80px)',
        width: '100%',
        height: '65px',
        backgroundColor: '#fff',
        zIndex: '10000',
        boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
        borderBottom: '1px solid #e6e6e6'
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={toggleSidebar}
      >
        <MenuOpenIcon
          css={{
            width: '32px',
            height: '65px',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        { isPhone &&
          <img
            src="icons/page/hello-star.png"
            alt="Standard Logo"
            style={{
              display: 'block',
              height: '22px'
            }}
          />
        }
        { !isPhone &&
          <Breadcrumb>
            {breadcrumbs.map(({ path, label }) => (
              <Breadcrumb.Item key={path}>
                <Link
                  to={path}
                  style={{
                    fontSize: '13px',
                    fontWeight: '500',
                    color: '#00141E',
                    textTransform: 'uppercase'
                  }}
                >
                  {label}
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        }
      </div>
      <div />
    </header>
  );
}

const mapStateToProps = state => {
  return {
    isPhone: Util.isPhone(state),
  };
};

export default withRouter(connect(mapStateToProps)(Header));
