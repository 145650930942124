import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  ignoreErrors: [
    'ChunkLoadError' // ignored to reduce noise until we have a proper serviceworker installed
  ]
});

export default Sentry;

