import { 
    createContext, 
    useContext, 
    useState,
    useEffect,
  } from 'react';
  
  export const BreadcrumbsContext = createContext();
  
  function BreadcrumbsProvider ({ 
    children, 
  }) {
  
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
      // This is the legacy approach; I feel like there is a more React-y way to do this
      const pageTitle = breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].label : '';
      document.title = `Hello – ${pageTitle}`;
    }, [
      breadcrumbs
    ]);
      
    return (
      <BreadcrumbsContext.Provider 
        value={{
          breadcrumbs,
          setBreadcrumbs,
        }}
      >
        { children }
      </BreadcrumbsContext.Provider >
    );
  }
  
  export const withBreadcrumbs = WrappedComponent => props => {
    const breadcrumbs = useBreadcrumbs();
    return <WrappedComponent {...props} {...breadcrumbs} />;
  };
  
  export const useBreadcrumbs = () => useContext(BreadcrumbsContext);
    
  export default BreadcrumbsProvider;
  