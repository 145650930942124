/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import PaddedPage from 'components/shared/PaddedPage';

const NotFound = props => {
  return (
    <PaddedPage>
      <div style={{ textAlign: 'center' }}>
        <h1>
          <span 
            aria-label="thinking_face" 
            role="img" 
            css={{
              fontSize: '72px',
            }}
          >
            🤔
          </span>
        </h1>
        <div style={{ marginBottom: 20 }}>Whoops! We couldn't find the page you were looking for.</div>
        <Link to="/">Get me out of here</Link>
      </div>
    </PaddedPage>
  );
};

export default NotFound;
