/* eslint-disable no-redeclare */
/* global window:false */
import React from 'react';
import { withRouter } from 'react-router-dom';

export class ScrollToTopBase extends React.Component {
  componentDidUpdate(prevProps) {
    const { _window, location } = this.props;

    if (_window && location !== prevProps.location) {
      _window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTopBase.defaultProps = {
  _window: typeof window !== 'undefined' ? window : null,
};

export default withRouter(ScrollToTopBase);
