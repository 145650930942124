import reduce from 'lodash/reduce';

// expects an dictionary of events grouped by month (string) with properties sponsorships, available_sponsorships, and price
export default function getSumOfUnsoldSponsorshipsForEvents(events) {
  return reduce(
    events,
    (sum, event) => {
      return event.available_sponsorships > 0 && (!event.sponsorships || event.sponsorships.length < event.available_sponsorships)
        ? sum + (event.available_sponsorships - event.sponsorships.length) * parseFloat(event.price)
        : sum;
    },
    0
  );
}
