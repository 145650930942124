// @flow

import { combineReducers } from 'redux';

import device from './device';
import resources from './resources';

export default combineReducers({
  device,
  resources,
});
