/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

const HelloLogoText = ({style}) => {
  return (
    <svg css={style} width="62" height="10" viewBox="0 0 62 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M54.1715 5.00757C54.1715 7.41728 55.6985 9.09862 57.9337 9.09862C60.1689 9.09862 61.7175 7.41729 61.7175 4.99544C61.7175 2.57359 60.1904 0.904388 57.9553 0.904388C55.7201 0.904388 54.1715 2.59786 54.1715 5.00757ZM55.0335 4.97419C55.0335 2.98937 56.185 1.6358 57.9337 1.6358C59.6948 1.6358 60.8431 2.99847 60.8431 4.99544C60.8431 6.9924 59.6948 8.33383 57.946 8.33383C56.1973 8.33383 55.0335 6.95902 55.0335 4.97419ZM5.80453 8.94687V5.1836H1.18642V8.94687H0.355164V1.03489H1.18642V4.43094H5.80453V1.03489H6.63578V8.94687H5.80453ZM16.0721 8.94687V1.03489H20.4131V1.81486H16.9034V4.44611H19.6742L19.7974 5.20788H16.8972V8.18815H20.5394V8.94687H16.0721ZM29.4061 1.03489V8.94687L33.5686 8.95294L33.4454 8.18815H30.2374V1.03489H29.4061ZM42.2414 8.94687V1.03489H43.0727V8.18815H46.2838L46.4008 8.94687H42.2414Z" fill="white"/>
    </svg>
  )
};

export default HelloLogoText;