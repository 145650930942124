/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import { fetchResourceList } from 'actions';
import { values as _values } from 'lodash-es';
import { Helmet } from 'react-helmet';
import { useAuth } from 'components/provider/Auth';
import { withRouter } from 'react-router-dom';
import Layout from './lib/Layout';
import { useCallback, useEffect } from 'react';
import Routes from './lib/Routes';
import ScrollToTop from './lib/ScrollToTop';

function getDeviceType() {
  return window.matchMedia("(max-width: 480px)").matches ? 'PHONE' : 'DESKTOP';
}

const App = ({
  fetchProperties,
  setDeviceType,
}) => {

  const { 
    account,
    token,
  } = useAuth();

  const getProperties = useCallback(
    async () => {
      if (account && token) {
        await fetchProperties(token);
      }
    },
    [
      account,
      fetchProperties,
      token
    ]
  );

  useEffect(() => {
    setDeviceType(getDeviceType());
  }, [
    setDeviceType
  ]);

  useEffect(() => getProperties, [
    account,
    getProperties,
  ]);

  return (
    <ScrollToTop>
      <div id="Hello">
        <Helmet
          defaultTitle={process.env.REACT_APP_NAME}
          titleTemplate={`%s | ${process.env.REACT_APP_NAME}`}
        >
          <title>Hello</title>
          <meta property="og:title" content="Hello" />
          <meta property="og:type" content="website" />
        </Helmet>
        <Layout>
          <Routes />
        </Layout>
      </div>
    </ScrollToTop>
  )
};

const mapStateToProps = state => {
  return {
    alert: state.alert,
    propertiesArray: state.resources.properties ? _values(state.resources.properties) : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProperties: token => dispatch(fetchResourceList('properties', { pageSize: 1000, token })),
  setDeviceType: deviceType => dispatch({ type: 'SET_DEVICE_TYPE', value: deviceType })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
