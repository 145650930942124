// @flow
import { connect } from 'react-redux';
import { withAuth } from 'components/provider/Auth';
import { withRouter, Route } from 'react-router-dom';
import LoginRedirect from '../LoginRedirect';
import PaddedPage from 'components/shared/PaddedPage';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PrivateRoute extends Component {
  render() {
    const { component: TheComponent, account, render, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          if (typeof account === 'undefined') return <PaddedPage {...props} />;
          if (account === null) return <LoginRedirect {...props} />;
          return render ? render(props) : <TheComponent {...props} />;
        }}
      />
    );
  }
}

PrivateRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  withAuth(
    connect()(PrivateRoute)
  )
);
