/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

const HelloStar = ({style}) => {
  return (
    <svg css={style} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.51346 5.17298L0.344849 8.15932H5.50173L7.09652 12.9939L8.68823 8.15932H13.8451L9.67342 5.17298L11.2682 0.338379L7.09652 3.32472L2.92483 0.338379L4.51346 5.17298Z" fill="#6FBCE6"/>
    </svg>
  )
};

export default HelloStar;