/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

const Modal = ({ 
  children,
  height = 430,
  isActive,
  onCancel,
  showCancelButton = false,
  title,
  width = 328,
  containerStyle = {},
  bodyStyle = {},
}) => {

  return (
    <div
      css={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10001,
        opacity: isActive ? 1 : 0,
        pointerEvents: isActive ? 'all' : 'none',
        transition: 'opacity ease-in-out 0.3s',
      }}
    >
      <div
        css={{
          background: 'rgba(0,0,0,0.7)',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
        onClick={onCancel}
      />
      <div
        css={{
          position: 'relative',
          width: width || 328,
          height: height || 'auto',
          background: 'white',
          borderRadius: 4,
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 10001,
          boxShadow: '0px 8px 40px 0px #0000001A',
          ...containerStyle,
        }}
      >
        { showCancelButton && (
          <div
            css={{
              position: 'absolute',
              top: 20,
              right: 20,
              cursor: 'pointer',
              color: '#6fbce6',
              fontSize: 24,
              width: 28,
              height: 28,
              backgroundColor: '#9999991A',
              borderRadius: '50%',
            }}
            onClick={onCancel}
          >
            <svg css={{ position: 'absolute', top: 8.5, left: 8.5 }} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L9 9" stroke="#888F99" strokeWidth="2" strokeLinecap="round"/>
              <path d="M1 9L9 1" stroke="#888F99" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
        )}
        { title && (
          <div
            css={{
              width: '100%',
              height: 69,
              textAlign: 'center',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid rgba(153, 153, 153, 0.1)',
            }}
          >
            { title }
          </div>
        )}
        <div
          css={{
            padding: '24px',
            ...bodyStyle,
          }}
        >
          { children }
        </div>
      </div>
    </div>
  );
  
}

export default Modal;
