/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import AccountPicker from './lib/AccountPicker';
import Alert from './lib/Alert';
import DesktopNavHeader from './lib/DesktopNavHeader';
import Header from './lib/Header';
import NavigationContainer from '../Navigation';
import PhoneNavHeader from './lib/PhoneNavHeader';
import UserNameAndIconContainer from './lib/UserNameAndIcon';

const Layout = ({ 
  isPhone, 
  account = false, 
  isSidebarExpanded, 
  children 
}) => {

  const [ isAccountPickerModalActive, setIsAccountPickerModalActive ] = useState(false);

  return (
    <div
      css={{
        position: 'relative',
        width: '100vw',
        height: '100%',
      }}
    >
      <aside
        css={{
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          backgroundColor: '#00141C',
          width: isPhone ? '82vw' : 256,
          transition: isPhone ? 'left cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s' : 'left ease-in-out 0.3s',
          left: !isPhone ?
            0 :
            !account ?
              '-82vw' :
              isSidebarExpanded ?
                0 :
                '-82vw',
          zIndex: !isPhone ? 1 : 1000,
        }}
      >
        { !isPhone && <DesktopNavHeader /> }
        { isPhone && <PhoneNavHeader/> }
        <NavigationContainer />
        <UserNameAndIconContainer 
          isAccountPickerModalActive={isAccountPickerModalActive}
          setIsAccountPickerModalActive={setIsAccountPickerModalActive}
        />
      </aside>
      <main
        css={{
          position: 'absolute',
          top: '0px',
          left: !account ?
            0 :
            isPhone ?
              0 :
              isSidebarExpanded ?
                256 :
                80,
          bottom: '0px',
          right: '0px',
          transition: 'left ease-in-out 0.3s',
          backgroundColor: account ? '#F0F2F5' : '#fff',
          zIndex: isPhone ? 1 : 1000,
        }}
      >
        <Header/>
        <div
          css={{
            position: 'absolute',
            top: '65px',
            left: '0px',
            bottom: '0px',
            right: '0px',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {children}
        </div>
      </main>
      <Alert />
      <AccountPicker
        isActive={isAccountPickerModalActive}
        setIsActive={setIsAccountPickerModalActive}
      />

    </div>
  );
};

export default Layout;
