/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useAuth } from 'components/provider/Auth';
import { useCallback, useState } from 'react';
import Button from 'components/shared/Button';
import Flexbox from 'flexbox-react';
import Input from 'components/shared/Input';

const LoginForm = ({
  onSubmit
}) => {

  const {
    login,
    setPassword,
    setUsername
  } = useAuth();

  const [ email, setEmail ] = useState('');
  const [ password, setPass ] = useState('');

  const submit = useCallback(() => {
    setUsername(email);
    setPassword(password);
    login();
    onSubmit();
  }, [
    email, 
    password,
    setUsername,
    setPassword,
    login,
    onSubmit
  ]);

  return (
    <div 
      css={{ 
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      
      <label htmlFor="email">Email</label>
      <Input 
        name="email" 
        value={email} 
        onPressEnter={submit} 
        onChange={setEmail} 
        style={{ marginBottom: 10 }}
      />

      <label htmlFor="password">Password</label>
      <Input 
        name="password" 
        value={password} 
        onPressEnter={submit} 
        onChange={setPass} 
        type="password" 
        style={{ marginBottom: 10 }}
      />

      <Flexbox justifyContent="space-between" paddingTop="10px">
        <Button type="primary" onClick={submit}>
          Submit
        </Button>
      </Flexbox>

    </div>
  )
}

export default LoginForm;