import React, { Component } from 'react';
import { withAuth } from 'components/provider/Auth';
import { withAlert } from 'components/provider/Alert';
import { withUi } from 'components/provider/Ui';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import View from './view.js';

class LayoutContainer extends Component {
  render() {
    return <View {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    isPhone: state.device.type === 'PHONE',
  };
};

export default withRouter(
  withAuth(
    withAlert(
      withUi(
        connect(mapStateToProps)(LayoutContainer)
      )
    )
  )
);
