import { 
  createContext, 
  useCallback,
  useContext, 
  useState,
  useEffect,
} from 'react';
  
export const UiContext = createContext();

function UiProvider ({ 
  children, 
}) {

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  useEffect(() => {
    const savedIsSidebarExpanded = localStorage.getItem('isSidebarExpanded');
    const defaultState = {
      isSidebarExpanded: savedIsSidebarExpanded ? JSON.parse(savedIsSidebarExpanded) : true
    }
    setIsSidebarExpanded(defaultState.isSidebarExpanded);
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarExpanded(!isSidebarExpanded);
  }, [
    isSidebarExpanded,
    setIsSidebarExpanded,
  ]);

  useEffect(() => {
    localStorage.setItem('isSidebarExpanded', isSidebarExpanded);
  }, [
    isSidebarExpanded,
  ]);
    
  return (
    <UiContext.Provider 
      value={{
        isSidebarExpanded,
        setIsSidebarExpanded,
        toggleSidebar,
      }}
    >
      { children }
    </UiContext.Provider >
  );
}

export const withUi = WrappedComponent => props => {
  const ui = useUi();
  return <WrappedComponent {...props} {...ui} />;
};

export const useUi = () => useContext(UiContext);
  
export default UiProvider;
