import { Redirect, Route, withRouter } from 'react-router-dom';
import { withAuth } from 'components/provider/Auth';
import React, { useEffect } from 'react';

const LoginRedirect = ({
  component: TheComponent,
  render,
  ...props
}) => {
  const { location, setLoginRedirectTo } = props;

  // trigger on component mount
  useEffect(() => {
    setLoginRedirectTo(location.pathname);
  }, [setLoginRedirectTo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Route
      {...props}
      render={() => {
        return <Redirect to={'/login'} />;
      }}
    />
  );
};

export default withRouter(withAuth(LoginRedirect));
