/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { colors } from 'consts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useUi } from 'components/provider/Ui';
import React from 'react'; // eslint-disable-line no-unused-vars

const MenuItem = ({ to, icon, isPhone, location, text }) => {
  const { isSidebarExpanded, toggleSidebar } = useUi();
  const currentPath = to.split(/(\?|#)/)[0];
  const isExternalLink = to.startsWith('http://') || to.startsWith('https://');

  // Improved check to prevent false positives in path matching
  const isCurrentPath = (expectedPath, currentLocation) => {
    // Ensure paths both end in a "/" to avoid partial matches
    const formatPath = path => path.endsWith('/') ? path : `${path}/`;
    const formattedExpectedPath = formatPath(expectedPath);
    const formattedCurrentLocation = formatPath(currentLocation);

    // Check if the current location starts with the expected path
    // and that the next character in the current location (if any) is a "/"
    return formattedCurrentLocation.startsWith(formattedExpectedPath);
  };

  const handleOnClick = () => {
    if (isPhone) {
      toggleSidebar();
    }
  };

  const sharedLinkStyle = {
    display: 'grid',
    gridTemplateColumns: '28px 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: '"icon text"',
    padding: '11.25px 29px',
    cursor: 'pointer',
    backgroundColor: isCurrentPath(currentPath, location.pathname) ? colors.standard.blue : 'transparent',
    color: isCurrentPath(currentPath, location.pathname) ? '#00141C' : '#788287',
    fill: isCurrentPath(currentPath, location.pathname) ? '#00141C' : '#788287',
    transition: 'none',
    textDecoration: 'none',
    '&:hover': {
      color: '#fff',
      fill: '#fff',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  };

  const LinkContent = (
    <>
      <div
        css={{
          gridArea: 'icon',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        { icon }
      </div>
      <div
        css={{
          gridArea: 'text',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          letterSpacing: '0.75px',
          transition: 'transform ease-in-out 0.3s, opacity ease-in-out 0.3s',
          transform: (isPhone || isSidebarExpanded) ? 'translateX(0px)' : 'translateX(50px)',
          opacity: (isPhone || isSidebarExpanded) ? '1' : '0',
        }}
      >
        <div
          css={{
            color: 'inherit',
            '&:hover': {
              color: 'inherit'
            }
          }}
        >
          {text}
        </div>
      </div>
    </>
  )

  return isExternalLink ? (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleOnClick}
      css={sharedLinkStyle}
    >
      {LinkContent}
    </a>
  ) : (
    <Link
      to={to}
      onClick={handleOnClick}
      css={sharedLinkStyle}
    >
      {LinkContent}
    </Link>
  );

};

const mapStateToProps = state => {
  return {
    isPhone: state.device.type === 'PHONE',
  };
};

export default withRouter(connect(mapStateToProps)(MenuItem));
