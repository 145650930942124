import './index.css';
import 'url-search-params-polyfill';
import { BrowserRouter } from 'react-router-dom';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { Provider as ReduxProvider} from 'react-redux';
import AppContainer from './components/App';
import AlertProvider from 'components/provider/Alert';
import AuthProvider from 'components/provider/Auth';
import BreadcrumbsProvider from 'components/provider/Breadcrumbs';
import UiProvider from 'components/provider/Ui';
import HelloAPIStatusProvider from './components/provider/HelloApi';
import React from 'react';
import ReactDOM from 'react-dom';
import reducer from './reducers/index';
import * as Sentry from '@sentry/browser';
import thunkMiddleware from 'redux-thunk';
import Util from 'utilities';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  ignoreErrors: [
    'ChunkLoadError' // ignored to reduce noise until we have a proper serviceworker installed
  ]
});

const appleTouchIconSizes = [57, 60, 72, 76, 114, 120, 144, 152, 180, 192];
appleTouchIconSizes.map(size => {
  Util.createAndAppendIconLink('apple-touch-icon', size);
  return size;
});

const iconSizes = [16, 32, 96, 192];
iconSizes.map(size => {
  Util.createAndAppendIconLink('icon', size);
  return size;
});

Util.createAndAppendIconLink('shortcut icon', 120);

function promiseMiddleware(next) {
  return action => {
    return action && typeof action.then === 'function' ? action.then(next) : next(action);
  };
}

const middlewares = [];
if (!!process.env.REACT_APP_USE_LOGGER) middlewares.push(createLogger());

const store = createStore(reducer, applyMiddleware(...middlewares), applyMiddleware(thunkMiddleware), applyMiddleware(promiseMiddleware));

ReactDOM.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <AlertProvider>
        <HelloAPIStatusProvider>
          <AuthProvider>
            <BreadcrumbsProvider>
              <UiProvider>
                <AppContainer />
              </UiProvider>
            </BreadcrumbsProvider>
          </AuthProvider>
        </HelloAPIStatusProvider>
      </AlertProvider>
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);
