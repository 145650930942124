/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import HelloLogoText from 'components/shared/HelloLogoText';
import HelloStar from 'components/shared/HelloStar';

const MaintenanceModeView = ({ message }) => (
  <div
    css={{
      position: 'absolute',
      top: '0px',
      left: '0px',
      bottom: '0px',
      right: '0px',
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      paddingTop: 100,
    }}
  >
    <div
      css={{
        paddingBottom: 50,
        width: 150,
      }}
    >
      <HelloStar
        style={{
          position: 'relative',
          left: '3px',
          height: 40,
          width: 40,
          marginRight: '10px'
        }}
      />
      <HelloLogoText
        style={{
          position: 'relative',
          left: 4,
          height: 40,
          width: 90,
          stroke: '#00141C',
        }}
      />
    </div>
    <h1
      css={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginTop: '1rem',
        marginBottom: '0.5rem',
      }}
    >
      Down For Maintenance
    </h1>
    <p
      css={{
        fontSize: '1rem',
        marginBottom: '1rem',
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      { message }
    </p>
  </div>
);

export default MaintenanceModeView;