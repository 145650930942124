export const colors = {
  standard: {
    black: '#000',
    blue: '#6fbce6',
    silver: '#d2d2d2',
    gray: '#aaabac',
    dark: '#80898e',
    // green: '#6fe67d',
    green: '#00D9B1',
    orange: '#ffb223',
    red: '#CD2832',
    lightRed: '#E7939A',
    white: '#fff',
    yellow: '#e5e66f',
    aruba: '#00D9B1'
  }
};
