// @flow

import { get as _get, merge as _merge, omit as _omit } from 'lodash-es';

import Util from 'utilities';

import { CLEAR_RESOURCES, DELETE_RESOURCE_DETAIL_SUCCESS, FETCH_RESOURCE_DETAIL_SUCCESS, FETCH_RESOURCE_LIST_SUCCESS } from 'actions';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_RESOURCES:
      return action.resourceType
        ? Object.assign({}, state, {
            [action.resourceType]: {}
          })
        : {};
    case FETCH_RESOURCE_LIST_SUCCESS:
      const stateWithUpdatedList = Object.assign({}, state, {
        [action.resourceType]: Object.assign({}, state[action.resourceType], Util.resourceArrayToObject(action.response.data))
      });
      return Util.storeIncludes(stateWithUpdatedList, action.response.included);
    case FETCH_RESOURCE_DETAIL_SUCCESS:
      // If fetchResourceDetail was called with `mergeProperties: true`, then merge the new resource
      // detail with the one that's already in the store, instead of replacing it
      const resource = action.mergeProperties ? _merge({}, _get(state, `[${action.resourceType}][${action.response.data.id}]`, {}), action.response.data) : action.response.data;

      const stateWithUpdatedDetail = Object.assign({}, state, {
        [action.resourceType]: Object.assign({}, state[action.resourceType], {
          [action.response.data.id]: resource
        })
      });
      return Util.storeIncludes(stateWithUpdatedDetail, action.response.included);
    case DELETE_RESOURCE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        [action.resourceType]: _omit(_get(state, action.resourceType), action.id)
      });
    default:
      return state;
  }
};

export default reducer;
