import React, { Component } from 'react';
import { withAuth } from 'components/provider/Auth';
import { withUi } from 'components/provider/Ui';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Utils from 'utilities';

import View from './view.js';

class UserNameAndIconContainer extends Component {
  constructor(props) {
    super(props);
    this.showLogout = this.showLogout.bind(this);
    this.hideLogout = this.hideLogout.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAvatarClicked = this.handleAvatarClicked.bind(this);
    this.state = {
      isLogoutShowing: false
    }
  }
  showLogout() {
    this.setState({ isLogoutShowing: true })
  }
  hideLogout() {
    this.setState({ isLogoutShowing: false })
  }
  handleAvatarClicked() {
    console.log(this.props)
    const isAnyAccountAStaffAccount = this.props.accounts && this.props.accounts.some(a => a.data.isStaff);
    if (isAnyAccountAStaffAccount && this.props.setIsAccountPickerModalActive) {
      this.props.setIsAccountPickerModalActive(!this.props.isAccountPickerModalActive);
    } else {
      this.props.toggleSidebar();
    }
  }
  logout() {
    const { account, isPhone, isSidebarExpanded, toggleSidebar, logoutAccount } = this.props;
    if (isPhone && isSidebarExpanded) toggleSidebar();
    return logoutAccount(account);
  }
  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        showLogout={this.showLogout}
        hideLogout={this.hideLogout}
        logout={this.logout}
        handleAvatarClicked={this.handleAvatarClicked}
      />
    );
  }
}

const mapStateToProps = state => ({
  isPhone: Utils.isPhone(state),
});

export default withRouter(
  withAuth(
    withUi(
      connect(
        mapStateToProps
      )(UserNameAndIconContainer)
    )
  )
);
