import { connect } from 'react-redux';
import { values as _values } from 'lodash-es';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { withAuth } from 'components/provider/Auth';
import LoginRedirect from '../LoginRedirect';
import PropTypes from 'prop-types';
import React from 'react';

const HomeRoute = ({
  account,
  component: TheComponent,
  properties,
  render,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={props => {
        if (account === null) return <LoginRedirect {...props} />;
        if (!account?.data?.isSponsor && !account?.data?.isCreator && !account?.data?.isStaff) return null;
        if (account?.data?.isSponsor) return <Redirect to={'/sponsorships'} />;
        if (account?.data?.isCreator) {
          if (properties.length === 0) return null;
          if (properties.length === 1) return <Redirect to={`/schedule/${properties?.[0]?.attributes?.slug}#upcoming`} />;
        }
        return <Redirect to={'/schedule'} />
      }}
    />
  );
};

const mapStateToProps = state => ({
  properties: state.resources.properties ? _values(state.resources.properties) : [],
});

HomeRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  withAuth(
    connect(
      mapStateToProps,
    )(HomeRoute)
  )
);
