import React, { Component } from 'react';

export const BreadcrumbItem = ({ children }) => <div>{children}</div>;

export default class Breadcrumb extends Component {
  static Item = BreadcrumbItem;

  render() {
    const { children } = this.props;
    return React.Children.map(children, (child, i) => {
      return i > 0 ? (
        <React.Fragment>
          <div style={{ padding: '0px 10px', position: 'relative', top: '-1px' }}>
            ›
          </div>
          {child}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {child}
        </React.Fragment>
      )
    });
  }
};
