/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Component } from 'react';
import { colors } from 'consts';
import Utils from 'utilities';

class Input extends Component {
  constructor(props) {
    super(props);
    this.decrementValue = this.decrementValue.bind(this);
    this.formatValue = this.formatValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.incrementValue = this.incrementValue.bind(this);
    this.parseValue = this.parseValue.bind(this);
    this.setInputRef = this.setInputRef.bind(this);
    this.state = {
      value: ''
    }
  }

  componentDidMount() {
    if (this.props.autoFocus === true) this.input.focus();
    // The following check will return true for both null and undefined, but false for 0.
    if (this.props.initialValue != null) { 
      this.setState({
        value: this.formatValue(this.props.initialValue)
      });
    }
  }

  decrementValue() {
    const { value } = this.state;
    const parsedValue = this.parseValue(value);
    if (Number.isInteger) {
      const decrementedValue = parsedValue - 1;
      const formattedValue = this.formatValue(decrementedValue);
      this.setState({ value: formattedValue });
    }
  }

  formatValue(value) {
    const { format, formatter } = this.props;
    let formattedValue = value;
    if (formatter) {
      formattedValue = formatter(value);
    } else if (format) {
      switch (format) {
        case 'dollar':
          formattedValue = value ? Utils.renderCurrency(value) : '';
          break;
        default:
          break;
      }
    }
    return formattedValue;
  }

  handleChange(e) {
    const { onChange } = this.props;
    const rawValue = this.parseValue(e.target.value);
    if (onChange) onChange(rawValue, e);
    const displayValue = this.formatValue(rawValue);
    this.setState({ value: displayValue });
    return;
  }

  handleKeyDown(e) {
    const { onPressEnter } = this.props;
    switch (e.keyCode) {
      case 13:
        if (onPressEnter) onPressEnter(e.target.value);
        this.input.blur();
        break;
      case 38:
        this.incrementValue();
        break;
      case 40:
        this.decrementValue();
        break;
      default:
        break;
    }
  }

  incrementValue() {
    const { value } = this.state;
    const parsedValue = this.parseValue(value);
    if (Number.isInteger) {
      const incrementedValue = parsedValue + 1;
      const formattedValue = this.formatValue(incrementedValue);
      this.setState({ value: formattedValue });
    }
  }

  parseValue(value) {
    const { format, parser } = this.props;
    let parsedValue = value;
    if (parser) {
      parsedValue = parser(value);
    } else if (format) {
      switch (format) {
        case 'dollar':
          parsedValue = value ? parseInt(value.replace(/\D/g, '')) : 0;
          break;
        default:
          break;
      }
    }
    return parsedValue;
  }

  setInputRef(el) {
    return (this.input = el);
  }

  render() {
    const { placeholder = '', style, type = 'text', disabled = false } = this.props;
    const { value } = this.state;

    const inputCSS = Object.assign({}, {
      appearance: 'none',
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      borderRadius: '5px',
      border: '1px solid #DDDDDD',
      boxSizing: 'border-box',
      color: 'inherit',
      display: 'inline-block',
      fontFamily: `"Nebula Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
      fontFeatureSettings: '"tnum"',
      fontSize: 'inherit',
      fontVariant: 'tabular-nums',
      height: '100%',
      lineHeight: '1.5',
      listStyle: 'none',
      margin: '0',
      padding: '4px 12px',
      position: 'relative',
      touchAction: 'manipulation',
      transition: 'all .3s',
      width: '100%',
      outline: 'none',
      '&::placeholder': {
        color: 'rgb(128, 137, 142, 0.3)'
      },
      '&:focus': {
        border: `1px solid ${colors.standard.blue}`,
        boxShadow: `0px 0px 5px ${colors.standard.blue}`,
        '&::placeholder': {
          color: 'hsl(201, 50%, 87%)'
        },
      }
    }, style);

    return (
      <input
        type={type}
        placeholder={placeholder}
        css={inputCSS}
        ref={this.setInputRef}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        value={value}
        disabled={disabled}
      />
    );
  }
}

export default Input;
