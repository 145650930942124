// @flow

const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_DEVICE':
      return {};
    case 'SET_DEVICE_TYPE':
      return Object.assign({}, state, {
        type: action.value
      });
    default:
      return state;
  }
};

export default reducer;
