/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { connect } from 'react-redux';
import React from 'react';
import Utils from 'utilities';

const PaddedPage = ({ children, isPhone, style }) => (
  <div
    css={Object.assign({}, {
      minHeight: '100%',
      padding: isPhone ? '10px 0px 0px' : '30px',
      backgroundColor: '#F0F2F5',
    }, style)}
  >
    <div
      css={{
        minHeight: '100%',
        padding: isPhone ? '26px 40px' : 30,
        backgroundColor: '#fff',
        borderRadius: 4,
      }}
    >
      {
        React.Children.map(children, child => {
          return child;
        })
      }
    </div>
  </div>
);

const mapStateToProps = state => {
  return {
    isPhone: Utils.isPhone(state),
  };
};

export default connect(mapStateToProps)(PaddedPage);
