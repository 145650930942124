// @flow
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import Util from 'utilities';
import { Deserializer } from 'jsonapi-serializer';
import { flatten as _flatten, map as _map, mapKeys as _mapKeys, snakeCase as _snakeCase } from 'lodash-es';

export const config = {};
config.API_HOST = process.env.REACT_APP_HELLO_API_URL; // config.API_HOST is used in other files

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: config.API_HOST
    });
    this.cache = setupCache({
      invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
          await config.store.removeItem(config.uuid)
        }
      },
      maxAge: 15 * 60 * 1000,
      debug: false,
      exclude: {
        query: false
      },
      key: request => `${request.url}?${Util.url.serialize(request.params)}`
    });
    this.deserializer = new Deserializer({ keyForAttribute: 'snake_case' });
  }

  headers(token) {
    let headers = {
      'Content-Type': 'application/vnd.api+json'
    };

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return headers;
  }

  get(url, token, params, args, skipCache = false) {
    return this.client(
      Object.assign(
        {},
        {
          headers: this.headers(token),
          method: 'GET',
          url,
          params
        },
        {
          adapter: skipCache ? null : this.cache.adapter,
          ...args
        }
      )
    );
  }

  async getAsync(args, { deserialize = true, responseType, skipCache, token }) {
    const baseArgs = {
      headers: this.headers(token),
      method: 'get',
    };
    if (!skipCache) baseArgs.adapter = this.cache.adapter;
    if (responseType) baseArgs.responseType = responseType;
    if (args.url) {
      args.url = args.url.split('').pop() === '/' 
        ? args.url 
        : args.url.includes('?')
          ? args.url
          : `${args.url}/`;
    }
    if (args.params) {
      if (args.params.include) args.params.include = args.params.include.join(',');
      if (args.params.fields) {
        _map(args.params.fields, (value, key) => (args.params[`fields[${key}]`] = value.join(',')));
        delete(args.params.fields);
      }
      if (args.params.filters) {
        _map(args.params.filters, (value, key) => (args.params[`filter[${key}]`] = value));
        delete(args.params.filters);
      }
    }
    const argsWithMappedKeys = _mapKeys(args, (_, key) => _snakeCase(key));
    const request = Object.assign({}, baseArgs, argsWithMappedKeys);
    const response = await this.client(request);
    const meta = response?.data?.meta;
    let data = response?.data;
    if (deserialize) { data = await this.deserializer.deserialize(response.data); }
    return {
      data,
      meta,
      raw: response
    };
  }

  async getRecursively(args, opts) {
    let modArgs = args,
        next = args.url,
        results = [];
    while (next !== null) {
      const response = await this.getAsync(modArgs, opts);
      results.push(response.data);
      next = response?.raw?.data?.links?.next || null;
      modArgs = {
        url: decodeURI(next)
      }
    }
    return _flatten(results);
  }

  post(url, token, params, data) {
    return this.client({
      headers: this.headers(token),
      method: 'post',
      url,
      params,
      data: { data }
    });
  }

  async postAsync(url, data, params, token, deserialize = true, { useJsonApi } = { useJsonApi: true }) {
    const slashedUrl = url.split('').pop() === '/' ? url : `${url}/`;
    const headers = this.headers(token);
    if (!useJsonApi) {
      headers['Content-Type'] = 'application/json';
    }
    const postData = useJsonApi ? { data } : data;
    const response = await this.client({
      headers: headers,
      method: 'post',
      url: slashedUrl,
      params,
      data: postData,
    });
    if (deserialize === false) return response;
    const deserializedResponse = await this.deserializer.deserialize(response.data);
    return deserializedResponse;
  }

  // this is a hack to support application/json POSTs
  // you can also use the postAsync method with the useJsonApi option set to false
  async postJSON(url, data, params, token) {
    const slashedUrl = url.split('').pop() === '/' ? url : `${url}/`;
    const response = await this.client({
      headers: Object.assign({}, this.headers(token), { 'Content-Type': 'application/json' }),
      method: 'post',
      url: slashedUrl,
      params,
      data
    });
    return response.data;
  }

  patch(url, token, data) {
    return this.client({
      headers: this.headers(token),
      method: 'PATCH',
      url,
      data: { data }
    });
  }

  async patchAsync(url, data, token, deserialize = true, { useJsonApi } = { useJsonApi: true }) {
    const slashedUrl = url.split('').pop() === '/' ? url : `${url}/`;
    const headers = this.headers(token);
    if (!useJsonApi) {
      headers['Content-Type'] = 'application/json';
    }
    const postData = useJsonApi ? { data } : data;
    const response = await this.client({
      headers: headers,
      method: 'PATCH',
      url: slashedUrl,
      data: postData
    });
    if (deserialize === false) return response;
    const deserializedResponse = await this.deserializer.deserialize(response.data);
    return deserializedResponse;
  }

  delete(url, token) {
    return this.client({
      headers: this.headers(token),
      method: 'DELETE',
      url
    });
  }

  postForm(url, token, form) {
    var params = new URLSearchParams();
    Object.keys(form).forEach(function(key) {
      params.append(key, form[key]);
    });

    const headers = Object.assign({}, this.headers(token), {
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.client({
      headers: headers,
      method: 'post',
      url,
      data: params
    });
  }

  postFile(url, token, file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.client({
      headers: { ...this.headers(token), 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url,
      data: formData
    });
  }
}

export default new Api();
