/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const Avatar = ({ account, onClick }) => {
  return (
    <div
      css={{
        backgroundColor: 'rgba(255,255,255,0.075)',
        backgroundImage: `url(${account?.data?.image_url || ''})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        border: '1px solid #788287',
        borderRadius: '20px',
        cursor: 'pointer',
        height: '36px',
        marginRight: '14px',
        width: '36px',
      }}
      onClick={onClick}
    />
  );
};

Avatar.propTypes = {
  account: PropTypes.object
};

export default Avatar;
