/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { useUi } from 'components/provider/Ui';
import HelloLogoText from 'components/shared/HelloLogoText';
import HelloStar from 'components/shared/HelloStar';

const PhoneNavHeader = () => {
  const { toggleSidebar } = useUi();
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '70px 1fr 70px',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"left center right"',
        width: '100%',
        height: '65px',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={toggleSidebar}
      >
        <CloseIcon
          css={{
            width: '50px',
            fill: '#fff',
            position: 'relative',
            left: 5,
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 10,
        }}
      >
        <HelloStar/>
        <HelloLogoText/>
      </div>
      <div />
    </div>
  )
}

export default PhoneNavHeader;
