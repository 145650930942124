import { connect } from 'react-redux';
import { withAuth } from 'components/provider/Auth';
import { withRouter, Route } from 'react-router-dom';
import LoginRedirect from '../LoginRedirect';
import PaddedPage from 'components/shared/PaddedPage';
import NotFound from 'components/route/NotFound';
import PropTypes from 'prop-types';
import React from 'react';

const StaffOrCreatorRoute = ({
  account,
  blockInactiveCreators = false,
  component: TheComponent,
  render,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={props => {
        if (typeof account === 'undefined') return <PaddedPage {...props} />;
        if (account === null) return <LoginRedirect {...props} />;
        if (!account?.data?.isStaff && !account?.data?.isCreator) return <NotFound {...props} />;
        if (blockInactiveCreators && account?.data?.isCreator && account?.data?.isReadyOnlyCreator) return <NotFound {...props} />;
        return render ? render(props) : <TheComponent {...props} />;
      }}
    />
  );
};

StaffOrCreatorRoute.contextTypes = {
  component: PropTypes.object
};

export default withRouter(
  withAuth(
    connect(
      null,
    )(StaffOrCreatorRoute)
  )
);
