import { compact as _compact, map as _map, snakeCase as _snakeCase } from 'lodash-es';

import Api from './api';
import Util from 'utilities';

export const CLEAR_AUTH = 'CLEAR_AUTH';
export const CLEAR_RESOURCES = 'CLEAR_RESOURCES';
export const DELETE_RESOURCE_DETAIL_SUCCESS = 'DELETE_RESOURCE_DETAIL_SUCCESS';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
export const FETCH_RESOURCE_DETAIL_SUCCESS = 'FETCH_RESOURCE_DETAIL_SUCCESS';
export const FETCH_RESOURCE_LIST_SUCCESS = 'FETCH_RESOURCE_LIST_SUCCESS';
export const FETCHING_CURRENT_USER = 'FETCHING_CURRENT_USER';
export const SELECT_YOUTUBE_ACCOUNT = 'SELECT_YOUTUBE_ACCOUNT';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SHOW_ACCOUNT_PICKER = 'SHOW_ACCOUNT_PICKER';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const clearResources = resourceType => {
  return resourceType ? { type: CLEAR_RESOURCES, resourceType } : { type: CLEAR_RESOURCES };
};

export const selectYoutubeAccount = id => ({
  type: SELECT_YOUTUBE_ACCOUNT,
  id
});

export const fetchResourceListSuccess = (resourceType, response) => ({
  type: FETCH_RESOURCE_LIST_SUCCESS,
  resourceType,
  response
});

export const fetchResourceDetailSuccess = (resourceType, response, mergeProperties) => ({
  type: FETCH_RESOURCE_DETAIL_SUCCESS,
  resourceType,
  response,
  mergeProperties
});

export const deleteResourceDetailSuccess = (resourceType, id) => ({
  type: DELETE_RESOURCE_DETAIL_SUCCESS,
  resourceType,
  id
});

export const fetchResourceList = (resourceType, { action, fields = {}, filters = {}, include, page, path, pageSize, prefix, skipCache = false, sort, token } = {}) => (dispatch, getState) => {
  const data = {};
  if (include) data.include = include.join(',');
  if (page) data.page = page;
  if (pageSize) data.page_size = pageSize;
  if (sort) data.sort = sort;

  _map(filters, (value, key) => (data[`filter[${key}]`] = value));

  _map(fields, (value, key) => (data[`fields[${key}]`] = value.join(',')));

  const requestPath = path || _compact([prefix, resourceType, action]).join('/');

  return Api.get(requestPath + '/', token, data, null, skipCache).then(response => {
    dispatch(fetchResourceListSuccess(resourceType, response.data));

    return response.data;
  });
};

export const fetchResourceDetail = (resourceType, id, { include, action, mergeProperties, prefix, skipCache = false, token } = {}) => (dispatch, getState) => {
  const data = {};
  if (include) data.include = include.join(',');

  const path = _compact([prefix, resourceType, id, action]).join('/');

  return Api.get(path + '/', token, data, null, skipCache).then(response => {
    dispatch(fetchResourceDetailSuccess(resourceType, response.data, mergeProperties));

    return response.data;
  });
};

export const createResource = (resourceType, attributes, options = {}) => createResourceHelper(resourceType, attributes, Object.assign({}, { successAction: fetchResourceDetailSuccess }, options));

export const createResourceList = (resourceType, attributes, options = {}) => createResourceHelper(resourceType, attributes, Object.assign({}, options, { successAction: fetchResourceListSuccess }));

const createResourceHelper = (resourceType, attributes, { action, id, include, relationships, successAction, token } = {}) => (dispatch, getState) => {
  const data = {
    type: resourceType,
    attributes,
    relationships
  };

  const params = {};
  if (include) params.include = include.join(',');

  const pluralizedKey = Util.pluralizeKey(_snakeCase(resourceType));
  const path = _compact([pluralizedKey, id, action]).join('/') + '/';

  return Api.post(path, token, params, data).then(response => {
    dispatch(successAction(pluralizedKey, response.data));

    return response.data;
  });
};

export const editResource = (resourceType, id, attributes, { relationships, action, token } = {}) => (dispatch, getState) => {
  const data = {
    id,
    type: resourceType,
    attributes,
    relationships
  };

  const pluralizedKey = Util.pluralizeKey(_snakeCase(resourceType));
  const path = _compact([pluralizedKey, id, action]).join('/') + '/';

  return Api.patch(path, token, data).then(response => {
    dispatch(fetchResourceDetailSuccess(pluralizedKey, response.data));

    return response.data;
  });
};

export const deleteResource = (resourceType, id, { token }) => (dispatch, getState) => {
  const pluralizedKey = Util.pluralizeKey(_snakeCase(resourceType));

  return Api.delete(`${pluralizedKey}/${id}/`, token).then(response => {
    dispatch(deleteResourceDetailSuccess(pluralizedKey, id));

    return response.data;
  });
};
